import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';


// const theme = createTheme();

const uitheme = createTheme({
  palette: {
    primary: {
      light: '#9e9e9e',
      main: '#9e9e9e',
      dark: '#9e9e9e',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


export default function TokenCreator() {

  const contractAddress = "0x69877A8c02494D901cfC76083d37639805d79e07";
  const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"cOwner","type":"address"},{"internalType":"address","name":"marketingWallet","type":"address"},{"internalType":"string","name":"name","type":"string"},{"internalType":"string","name":"symbol","type":"string"},{"internalType":"uint256","name":"totalSupply","type":"uint256"},{"internalType":"uint256","name":"taxFee","type":"uint256"}],"name":"createToken","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
  const fee = "20000000000000000";

  const [nameText, setNameText] = React.useState(null);
  const [symbolText, setSymbolText] = React.useState(null);
  const [supplyText, setSupplyText] = React.useState(null);
  const [taxText, setTaxText] = React.useState(null);
  const [ownerText, setOwnerText] = React.useState(null);
  const [marketingText, setMarketingText] = React.useState(null);

  const handleNameChange = (event) => {
    setNameText(event.target.value);
  };

  const handleSymbolChange = (event) => {
    setSymbolText(event.target.value);
  };

  const handleSupplyChange = (event) => {
    setSupplyText(event.target.value);
  };

  const handleTaxChange = (event) => {
    setTaxText(event.target.value);
  };

  const handleOwnerChange = (event) => {
    setOwnerText(event.target.value);
  };

  const handleMarketingChange = (event) => {
    setMarketingText(event.target.value);
  };

  const checkWalletIsConnected = () => {
    // const { ethereum } = window;
    /* if(!ethereum){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    } */

   }


  const connectWalletHandler = async() => { 
    const { ethereum } = window;
    if(!ethereum){
      alert("Please Install Metamask!");
    }

    /* if(window.ethereum.networkVersion !== '56'){
      console.log("Please change the network to Binance Smart Chain", window.ethereum.networkVersion);
      alert("Please change the network to Binance Smart Chain Mainnet");
      return;
    }

    try{
      const accounts = await ethereum.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    } */
  }

  const connectWalletButton = () => {
    return (
      <Button onClick={connectWalletHandler} variant="contained" startIcon={<AccountBalanceWalletIcon />}>
        Wallet Connect
      </Button>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  
  const createToken = async() => {
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        // console.log("Intialize payment");
        const createTokens = await contract.createToken(ownerText, marketingText, nameText, symbolText, supplyText, taxText, {value: fee});
        // console.log(contract);
		if(createTokens){
			alert("Congratulations, your token has been created on smart chain Testnet.");
		}else{
			alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
		}
      }
    }catch(err){
		alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
    	// console.log(err);
    }

  }

  const createButton = () => {
    return (
      <Button onClick={createToken} variant="contained" startIcon={<AddCircleOutlineIcon />}>
        Create Token
      </Button>
    )
  }

  return (
    <ThemeProvider theme={uitheme}>
        <Container>
          {/* End hero unit */}
            <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
            }}
            noValidate
            autoComplete="off"
            alignItems="center"
            justifyContent="center"
            >
                <div
                style={{
                  position: 'absolute', 
                  left: '50%', 
                  top: '50%', transform: 'translate(-50%, -50%)'}}>
                    <TextField
                    required
                    id="tname"
                    label="Token Name"
                    placeholder="Bitcoin"
                    value={nameText}
                    onChange={handleNameChange}
                    color="primary"
                    focused
                    inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                    />

                    <TextField
                    required
                    id="symbol"
                    label="Symbol"
                    placeholder="BTC"
                    value={symbolText}
                    onChange={handleSymbolChange}
                    color="primary"
                    focused
                    inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                    />

                    <TextField
                    required
                    id="supply"
                    label="Supply"
                    type="number"
                    placeholder="100000000"
                    value={supplyText}
                    onChange={handleSupplyChange}
                    color="primary"
                    focused
                    inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                    />

                    <TextField
                    required
                    id="tax"
                    label="Tax %"
                    type="number"
                    placeholder="2"
                    value={taxText}
                    onChange={handleTaxChange}
                    color="primary"
                    focused
                    inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                    />

                    <TextField
                    required
                    id="owner"
                    label="Owner Wallet"
                    placeholder="0xAb8a3dD7D4Cd76f094283058F544e6bd67c546AC"
                    value={ownerText}
                    onChange={handleOwnerChange}
                    color="primary"
                    focused
                    inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                    />

                    <TextField
                    required
                    id="marketing"
                    label="Marketing Wallet"
                    placeholder="0xAb8a3dD7D4Cd76f094283058F544e6bd67c546AC"
                    value={marketingText}
                    onChange={handleMarketingChange}
                    color="primary"
                    focused
                    inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px"
                          }}
                    >
                        {createButton()}
                    </div>
                </div>
                
            </Box>

        </Container>
        </ThemeProvider>
  );
}